import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public menuHelper: {
    name: string;
    icon: string;
    route: string;
    disabled: boolean;
  }[] = [
    {
      name: 'Home',
      icon: 'home',
      route: '/',
      disabled: false
    },
    {
      name: 'Mapa das cachaças',
      icon: 'map',
      route: '/mapa',
      disabled: false
    },
    {
      name: 'Infográfico',
      icon: 'info',
      route: '/infografico',
      disabled: false
    }
  ];
}
