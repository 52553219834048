import { BottleService } from './../service/bottle.service';
import { Component, OnInit } from '@angular/core';

export interface StateElement {
  state: string;
  position: number;
  bottles: number;
}

export interface DataReturn{
  litters: number;
  dataReturn: Array<StateElement>;
}

/*const ELEMENT_DATA: StateElement[] = [
  {position: 1, state: 'São Paulo', bottles: 20},
  {position: 1, state: 'Minas Gerais', bottles: 20}
]; */

@Component({
  selector: 'app-infografic',
  templateUrl: './infografic.component.html',
  styleUrls: ['./infografic.component.scss']
})
export class InfograficComponent implements OnInit {

  breakpoint: number;

  projectsReceiver:any[] = []

  rowSpan = 1;
  constructor(private service: BottleService) {

  }

  displayedColumns: string[] = ['position', 'state', 'bottles'];
  dataSource;
  sender;
  litters;

  ngOnInit(): void {
    this.breakpoint = (window.innerWidth <= 450) ? 1 : 2;
    this.service.getMap().subscribe((res: DataReturn) => {
        this.sender = res.dataReturn;
        this.dataSource = res.dataReturn;
        this.litters = res.litters;
    })
  }

  rowHeight(): number{
    if(this.breakpoint === 1){
      this.rowSpan = 2;
      return 200;
    }else{
      this.rowSpan = 1;
      return 450;
    }
    return 0;
  }

  imageSize(): number{
    if (this.breakpoint === 1){
      return 50;
    }else{
      return 40;
    }
  }

}
