<svg version="1.1" id="OBJECTS" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
  <g id="states">
	<polygon id="PI" [class]="getColor('Piauí')" points="392.96,175.26 394.53,172.18 389.55,163.02 391.14,152.85 385.14,147.31 386.26,129.13
		382.88,129.5 379.5,125.88 376.89,134.2 373.95,134.2 368.53,142.34 370.79,152.51 366.49,158.28 369.66,169.13 359.48,169.13
		342.52,178.06 336.65,191.96 338.34,198.18 338.34,206.77 338.31,206.82 341.73,207 344.56,215.03 353.94,210.96 356.2,211.75
		361.52,203.16 358.69,200.56 363.33,197.5 374.86,199.09 385.73,190.12 393.51,183.71 391.36,178.4 	"/>
	<path id="PB" [class]="getColor('Paraíba')" d="M434.48,172.67l-9.49-2.85c0,0,3.4-6.72,3.56-7.2c0.16-0.47-5.7,0.4-5.7,0.4l-6.49,4.67l-2.77-3.96
		l0.4,13.21l-2.83,2.46l8.52,1.58l7.87-6.09l3.12,2.61l-3.12,6.01l0.99,4.59l7.52-5.7l10.68-5.54l6.38,1.23l1.25-8.21l-1.15-2.57
		l-16.53-1.92L434.48,172.67z"/>
	<path id="RN" [class]="getColor('Rio Grande do Norte')" d="M446.75,152.88L428.5,151l-14.9,12.73l2.77,3.96l6.49-4.67c0,0,5.85-0.87,5.7-0.4
		c-0.16,0.47-3.56,7.2-3.56,7.2l9.49,2.85l2.22-7.28l16.53,1.92L446.75,152.88z"/>
	<polygon id="CE" [class]="getColor('Ceará')" points="428.5,151 400,127.63 386.26,129.13 385.14,147.31 391.14,152.85 389.55,163.02
		394.53,172.18 392.96,175.26 403.79,176.39 408.53,181.69 411.16,179.4 413.99,176.94 413.6,163.73 	"/>
	<path id="PA" [class]="getColor('Pará')" d="M334.63,110.89L317.75,103l-7,2.13l0.88-5l-13.13-6.38l-4.13,2l-1-4.75l-1.62-0.16l-2.66,5.33
		l-7.44,3.03l-4.48,12.12l-5.16,0.09l-14.32-28.56l-12.49-2.57c0,0-0.23-4.77-0.41-8.26l-12.41-1.39L236,78.5l-16.63-2.88
		l-15.88,8.63l-1.5,10.6v6.78l11.31,13.11l16.28,6.33l-23.85,48.84l4.04,6.04l14.62,21.88l67,5.5l9.31-20.87l2.6-9.95l10.06-12.32
		l-3.17-7.69l11.08-6.33L334.63,110.89z"/>
	<polygon id="RR" [class]="getColor('Roraima')" points="193.38,80.5 190.25,65.5 194.63,54.38 190.13,49.63 189.88,41 182.88,41.25 181.5,48.88
		159.38,58.38 157.63,54.75 139.63,52.25 147,66.5 156.75,72.38 157.38,75 163.23,76.65 165.15,107.63 175.21,115.88 176.57,103.11
		186.18,106.16 190.13,95.31 202,94.85 203.5,84.25 	"/>
	<path id="AP" [class]="getColor('Amapá')" d="M257.69,82.85l14.32,28.56l5.16-0.09l4.48-12.12l7.44-3.03l2.66-5.33l1.62,0.16l3-10.5l-6.63-7.13
		l-2.38,2.38l-4.75-13.13l-3.5-13.75l-1,7.75l-8.75,8.5l-2.5,11.13l-11-4l-10.13-0.13l-0.97-0.11c0.19,3.49,0.41,8.26,0.41,8.26
		L257.69,82.85z"/>
	<polygon id="MA" [class]="getColor('Maranhão')" points="310.19,152.51 322.06,156.92 320.48,174.78 326.02,184.84 330.77,182.24 331.33,186.43
		325.34,195.24 333.14,206.55 338.31,206.82 338.34,206.77 338.34,198.18 336.65,191.96 342.52,178.06 359.48,169.13 369.66,169.13
		366.49,158.28 370.79,152.51 368.53,142.34 373.95,134.2 376.89,134.2 379.5,125.88 361.63,121.63 356.63,126.5 347.25,111.63
		342.63,114.63 334.63,110.89 321.27,146.18 	"/>
	<polygon id="MG" [class]="getColor('Minas Gerais')" points="393.28,283.08 400.07,266.58 385.48,266.69 380.28,260.47 352.59,250 337.55,259.88
		326.47,257.08 326.47,269.07 320.03,283.08 322.97,289.92 317.43,296.08 311.77,293.71 289.96,298.35 287.39,306.62 285.91,311.38
		283.96,312.93 294.7,310.44 302.62,311.46 302.28,315.53 321.95,311.46 326.02,326.16 332.8,326.16 329.3,336.67 333.71,344.58
		348.97,337.01 367.51,333.84 375.53,330.45 376.44,322.86 379.51,320.13 380.51,314.51 383.9,315.76 389.44,306.48 387.29,293.82
		397.58,290.88 	"/>
	<polygon id="PE" [class]="getColor('Pernambuco')" points="446.74,176.86 436.06,182.4 428.55,188.1 427.56,183.51 430.68,177.49 427.56,174.88
		419.69,180.98 411.16,179.4 408.53,181.69 403.79,176.39 392.96,175.26 391.36,178.4 393.51,183.71 385.73,190.12 391.29,199.17
		404.34,189.44 417.31,197.67 422.38,192.84 429.1,197.67 435.59,197.43 442.47,192.29 449.08,192.43 451.38,189.63 453.13,178.09
		"/>
	<polygon id="TO" [class]="getColor('Tocantins')" points="338.31,206.82 333.14,206.55 325.34,195.24 331.33,186.43 330.77,182.24 326.02,184.84
		320.48,174.78 322.06,156.92 310.19,152.51 313.36,160.2 303.3,172.52 300.7,182.47 291.39,203.34 286.68,213.9 285.68,234.88
		293.01,230.97 291.99,235.38 301.49,239.33 303.18,235.38 312.68,240.35 332.85,235.85 330.77,217.29 	"/>
	<polygon id="ES" [class]="getColor('Espirito Santo')" points="387.29,293.82 389.44,306.48 383.9,315.76 380.51,314.51 379.51,320.13 382.32,324.91
		389.44,325.41 389.88,320.75 402.75,306.88 403.38,293.75 397.58,290.88 	"/>
	<polygon id="RJ" [class]="getColor('Rio de Janeiro')" points="382.32,324.91 379.51,320.13 376.44,322.86 375.53,330.45 367.51,333.84 348.97,337.01
		351.13,342.88 376.88,344 380.63,336.38 388.75,332.75 389.44,325.41 	"/>
	<polygon id="SP" [class]="getColor('São Paulo')" points="333.71,344.58 329.3,336.67 332.8,326.16 326.02,326.16 321.95,311.46 302.28,315.53
		302.62,311.46 294.7,310.44 283.96,312.93 278.99,316.43 271.08,334.07 264.29,337.01 262.45,339.5 281.36,339.5 287.02,343
		297.87,343 301.94,361.31 310.64,362.67 310.53,365.84 317.17,368.07 325,360 342.25,352.13 351.13,342.88 348.97,337.01 	"/>
	<polygon id="PR" [class]="getColor('Paraná')" points="310.64,362.67 301.94,361.31 297.87,343 287.02,343 281.36,339.5 262.45,339.5 250.63,355.5
		246.38,371.13 254,371.13 257.38,379.38 282.04,383.47 279.67,381.1 288.26,376.24 299.34,379.52 301.83,376.24 308.75,376.75
		317.17,368.07 310.53,365.84 	"/>
	<polygon id="AL" [class]="getColor('Alagoas')" points="442.47,192.29 435.59,197.43 429.1,197.67 422.38,192.84 417.31,197.67 436.25,209.08
		440.75,207.13 440.5,202.88 449.08,192.43 	"/>
	<polygon id="BA" [class]="getColor('Bahia')" points="425.33,222.26 420.08,220.85 417.31,212.78 421.59,211.04 417.31,197.67 404.34,189.44
		391.29,199.17 385.73,190.12 374.86,199.09 363.33,197.5 358.69,200.56 361.52,203.16 356.2,211.75 353.94,210.96 344.56,215.03
		341.73,207 338.31,206.82 330.77,217.29 332.85,235.85 333.03,237.41 337.55,259.88 352.59,250 380.28,260.47 385.48,266.69
		400.07,266.58 393.28,283.08 397.58,290.88 403.38,293.75 409,285.88 410.5,265.25 408.88,239.13 420.13,233.25 	"/>
	<polygon id="SE" [class]="getColor('Sergipe')" points="421.59,211.04 417.31,212.78 420.08,220.85 425.33,222.26 430.38,211.63 436.25,209.08
		417.31,197.67 	"/>
	<polygon id="MS" [class]="getColor('Mato Grosso do Sul')" points="264.63,298.35 257.06,284.1 243.49,287.04 232.41,281.5 221.56,287.83 215.23,288.61
		216.13,292 212.63,312 207.63,313.5 212.75,320 212.13,334.13 225.75,336 234.13,333.63 238.5,354.13 246.63,351.75 250.63,355.5
		262.45,339.5 264.29,337.01 271.08,334.07 278.99,316.43 283.96,312.93 285.91,311.38 287.39,306.62 	"/>
	<polygon id="RO" [class]="getColor('Rondônia')" points="190.02,225.2 190.7,214.91 175.55,215.93 173.63,190.04 169.78,192.98 160.97,183.26
		153.16,183.6 147.85,192.98 140.05,192.98 140.05,197.5 134.06,196.94 132.02,199.09 123.88,197.62 119.98,202.44 125.16,205.12
		129.63,202.88 134,202.63 135,209.63 133.25,211.5 136.13,215.25 133.75,219.13 142.5,228.63 158.25,232.88 183.88,244
		195.56,229.84 	"/>
	<polygon id="AC" [class]="getColor('Acre')" points="119.98,202.44 88.05,185.86 49.38,174.25 45.63,177.38 55.75,193.75 53.5,199 64.88,204.25
		81.25,199.63 81.5,216.13 103.25,216.13 106.5,211 109.75,212.88 125.16,205.12 	"/>
	<path id="MT" [class]="getColor('Mato Grosso')" d="M224.39,197.84l-14.62-21.88c-0.86,5.71-2.34,15.33-2.34,15.33l-33.8-1.24l1.92,25.89l15.15-1.02
		l-0.68,10.29l5.54,4.63L183.88,244l5.25,28.5l18.75-0.63l-1,10l7.5,3.5l0.86,3.24l6.33-0.78l10.85-6.33l11.08,5.54l13.57-2.94
		l7.57,14.24l-3.96-16.39l6.33-10.06L284.53,259l1.15-24.12l1-20.99l4.71-10.55L224.39,197.84z"/>
	<polygon id="SC" [class]="getColor('Santa Catarina')" points="299.34,379.52 288.26,376.24 279.67,381.1 282.04,383.47 257.38,379.38 255.13,387.72
		273.34,389.46 287.13,401.45 297.42,402.92 294.59,410.49 297.5,411.5 307.13,405 310.13,392.13 308.75,376.75 301.83,376.24 	"/>
	<polygon id="RS" [class]="getColor('Rio Grande do Sul')" points="297.42,402.92 287.13,401.45 273.34,389.46 255.13,387.72 233.25,400.5 215.88,422.13
		224.25,420.5 232.63,431.75 236.63,428.5 255.63,440 262.38,448.75 257.13,452.5 257.5,459 267,453.88 272.88,440.38 289.5,428.13
		297.5,411.5 294.59,410.49 	"/>
	<path id="AM" [class]="getColor('Amazonas')" d="M119.98,202.44l3.91-4.82l8.14,1.47l2.03-2.15l5.99,0.57v-4.52h7.8l5.31-9.38l7.8-0.34l8.82,9.72
		l3.84-2.94l33.8,1.24c0,0,1.48-9.62,2.34-15.33l-4.04-6.04l23.85-48.84l-16.28-6.33L202,101.64v-6.78l-11.87,0.45l-3.96,10.85
		l-9.61-3.05l-1.36,12.77l-10.06-8.25l-1.92-30.98L157.38,75L133,89.88l-1.38-4.38L118.38,89l-1.13-10.13l-3.13-5.5l-8.5,4.13
		l-0.25,2.88l-17.13-1.5l1.5,6.5l7,1.63l1.13,4.5h-11v7.38l6.38,3.88l-2,3.75l3.38,3.75L87.88,144l-9.38-2l-4.13,5l-10-0.25
		L56.5,154L55,167.75l-8.5,2.88l2.88,3.63l38.67,11.61L119.98,202.44z"/>

		<polygon id="GO" [class]="getColor('Goiás')" points="333.03,237.41 332.85,235.85 312.68,240.35 303.18,235.38 301.49,239.33 291.99,235.38
			293.01,230.97 285.68,234.88 284.53,259 267.01,271.89 260.68,281.95 264.63,298.35 287.39,306.62 289.96,298.35 311.77,293.71
			317.43,296.08 322.97,289.92 320.03,283.08 326.47,269.07 326.47,257.08 337.55,259.88 		"/>
		<rect id="DF" x="314.3" y="252.7" [class]="getColor('Distrito Federal')" width="10.72" height="7.17"/>
</g>
</svg>
