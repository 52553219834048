<script type="text/javascript" src="https://leaverou.github.io/prefixfree/prefixfree.min.js"></script>
<body height="100px">
    <div>
        <section>
            <div id="action">
                <div id="keg">
                    <div id="pipe-handle"></div>
                    <div id="pipe"></div>
                    <div id="pipe-front"></div>
                </div>

                <div class="glass">
                    <div class="beer"></div>
                    <div class="handle">
                        <div class="top-right"></div>
                        <div class="bottom-right"></div>
                    </div>
                    <div class="front-glass"></div>
                </div>
            </div>
        </section>
        <div>
            <h1>
                BUDEGA DO CHICO
            </h1>
        </div>
    </div>
</body>
