<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span class="example-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>
<div style='text-align: center;'>
  <span class="mat-subheading-2">Estados com mais rótulos na Budega</span>
</div>

<div class="home-container">
  <app-mapa-brasil [states]="sender" ></app-mapa-brasil>
</div>

<div class="mat-body">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef> # </th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef> Estado </th>
      <td mat-cell *matCellDef="let element"> {{element.state}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="bottles">
      <th mat-header-cell *matHeaderCellDef> Rótulos </th>
      <td mat-cell *matCellDef="let element"> {{element.bottles}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div style="margin-top: 20px"></div>
<mat-divider></mat-divider>
<div style='text-align: center;'>
  <div>
    <span class="mat-subheading-2">Quantos Litros de Cachaça tem na Budega?</span>
  </div>
  <div style="margin-top: 20px; text-align: center;">
    <img src="../../assets/glass.png" />
  </div>
  <div>
    <span>São <b>{{ (litters / 1000).toFixed(2)}}</b> Litros de Cachaça, é muita cachaça!!!</span>
  </div>
  <div>
    Com isso é possível encher <b>{{ (litters / 60).toFixed(2) }}</b> copos de cachaça!
  </div>
  <div style="margin-top: 10px">
    <!-- <app-grafico-pizza [points]="dataGrafico"></app-grafico-pizza> -->
  </div>
</div>
<mat-divider></mat-divider>
<div class="center">
  <div>
    <span class="mat-subheading-2">Cidades com mais cachaças na Budega:</span>
  </div>
  <div class="center">
    <img class="max-city" src="../../assets/ipueiras.png" />
    <img src="../../assets/first_place.png" />
  </div>
  <div class="center">
    <img class="max-city" src="../../assets/salinas.png" />
    <img src="../../assets/second_place.png" />
  </div>
  <div class="center">
    <img class="max-city" src="../../assets/maranguape.png" />
    <img src="../../assets/third.png" />
  </div>
</div>
<!--
  <div>
    <app-footer [hasNext]="false"></app-footer>
  </div>
-->
