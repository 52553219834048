import { SearchDialogComponent } from './../search-dialog/search-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Bottle } from './../model/bottle';
import { BottleService } from './../service/bottle.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {FormControl} from '@angular/forms';
import {PageEvent} from '@angular/material/paginator';
import {fromEvent, Observable} from 'rxjs';
import {constGARRAFAS} from "../home/data.component"

import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { LocalBottle } from '../model/local-bottle';

export interface Section {
  name: string;
  content: string;
  icon: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  isSearch = false;

  constructor(
    private service: BottleService,
    private dialog: MatDialog) { }

  breakpoint: number;
  isLoading:Boolean = true;
  bottles;
  numberOfElements = 0;
  pagesSize = 0;
  pageEvent: PageEvent;
  loaded = false;
  index = 0;
  icon_name = "search";
  searchName = "";

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef

  ngOnChanges(): void {

  }

  ngOnInit(): void {
    this.breakpoint = (window.innerWidth <= 500) ? 1 : 3;
    //this.getBottles(this.searchName, 0, 5)
    /*this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );*/
    /*fromEvent(this.searchInput.nativeElement, 'keyup').pipe(

      // get value
      map((event: any) => {
        return event.target.value;
      })
      // if character length greater then 2
      , filter(res => res.length > 4)

      // Time in milliseconds between key events
      , debounceTime(1000)

      // If previous query is diffent from current
      , distinctUntilChanged()

      // subscription for response
    ).subscribe((text: string) => {
      this.getBottles(text, 0, 20)
      this.searchName = text
    }); */
    this.getLocalBottles();
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 500) ? 1 : 3;
  }

  getLocalBottles(){
    var content = constGARRAFAS
    var result = content.map((element:LocalBottle) => {
      var pais = "Brasil"
      if(element.name.indexOf("Toucano") > -1){
        pais = "USA"
      }else{
        pais = "Brasil"
      }
      element["folders"] = [];
      element["folders"] = [
        {
          name: 'Garrafa',
          content: element.size+"ML",
          icon: 'local_drink'
        },
        {
          name: 'Local',
          content:  pais == "Brasil" ? element.city : "California" + " - " + element.state + " - " + pais ,
          icon: 'place'
        },
        {
          name: 'Fabricante',
          content: element.fabricante+"",
          icon: 'tapas'
        },
        {
          name: 'Tipo',
          content: element.type + "",
          icon: 'local_offer'
        }
      ]

      if(element.name.indexOf("Premium") > -1 || element.name.indexOf("premium") > -1){
        element["folders"].push({
          name: "Selo",
          content: "PREMIUM",
          icon: 'emoji_events'
        })
      }

      /*.notes = {
        name: 'Um pouco sobre a cachaça',
        content: '900',
        icon: 'waves'
      }*/
      
      return element
    });
    this.bottles = result
    this.loaded = true;
    console.log("Carregado local.")
    
    return ""
  }

  getBottles(name, page, size){
    this.loaded = false;
    this.service.getBottles(name, page, size).subscribe((res) =>{
      this.numberOfElements = res.totalElements;
      this.pagesSize = res.totalPages;

      this.index = res.pageable.pageNumber;
      var content = res.content.map( (b:Bottle) => {
        b.folders = [
          {
            name: 'Garrafa',
            content: b.size+"ML",
            icon: 'local_drink'
          },
          {
            name: 'Local',
            content: b.place.city+ " - " + b.place.state + " - " + b.place.country,
            icon: 'place'
          },
          {
            name: 'Fabricante',
            content: b.brand+"",
            icon: 'tapas'
          },
          {
            name: 'Tipo',
            content: b.type + "",
            icon: 'local_offer'
          }
        ]

        if(b.name.indexOf("Premium") > -1 || b.name.indexOf("premium") > -1){
          b.folders.push({
            name: "Selo",
            content: "PREMIUM",
            icon: 'emoji_events'
          })
        }

        b.notes = {
          name: 'Um pouco sobre a cachaça',
          content: '900',
          icon: 'waves'
        }

        return b
      })

      this.bottles = content
      this.loaded = true;
      //this.index = (page + 1);
    }, (err) =>{
      console.log(err)
    })

  }

  setPageSizeOptions(event){
    console.log(event);
  }

  showSearch(){
    this.isSearch = !this.isSearch;
    this.icon_name = !this.isSearch ? "search" : "close"
    if(!this.isSearch){
      this.searchName = "";
      this.getBottles("", 0, 20);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
}
