<div style="width: 100%;">
  <p>
    <b>Filtrar por tipo:</b>
  </p>
  <p>
    <mat-chip-list aria-label="Fish selection">
      <mat-chip>Premium</mat-chip>
      <mat-chip>Extra Premium</mat-chip>
      <mat-chip>Salinas</mat-chip>
      <mat-chip>Pequenas</mat-chip>
      <mat-chip>Porcelana</mat-chip>
    </mat-chip-list>
  </p>
</div>
<div>
  <button mat-button mat-raised-button [style.width.%]="100" color="primary">Filtrar</button>
</div>
