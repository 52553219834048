<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span class="example-spacer"></span>
      <button mat-icon-button (click)="showSearch()" class="example-icon favorite-icon searle"  aria-label="">
        <mat-icon>{{icon_name}}</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div [hidden]="!isSearch" style="margin: 10px">
    <form class="example-form" style="font-size: 17px;" (keydown.enter)="$event.preventDefault()" autocomplete="off">
      <mat-form-field class="example-full-width">
        <mat-label>Digite as 4 primeiras letras do nome da cachaça:</mat-label>
        <input matInput autocomplete="off"  #searchInput placeholder="Ex. Amburana" >
      </mat-form-field>
    </form>
  </div>
<mat-progress-bar *ngIf="!loaded" mode="indeterminate"></mat-progress-bar>
  <div *ngIf="loaded" class="container mobile-mode-margin" fxLayout="row" fxLayoutAlign="space-between" >
  <div class='menu-separator'>
    Cachaças
  </div>
  <ng-container *ngIf="bottles.length > 0">
    <mat-card class="item" *ngFor="let it of bottles" style="margin-left: {{breakpoint}}%;" class="example-card" >
      <mat-card-header>
        <div mat-card-avatar [style.background.image]="it.image" class="example-header-image">
          <p class="letter" >{{ it.name | slice:0:1}}</p>
        </div>
        <mat-card-title>{{it.name}}</mat-card-title>
        <mat-card-subtitle>{{ it.folders[2].content }}</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image src="assets/images/{{it.image}}" alt="{{it.name}}">
      <mat-card-content>
      </mat-card-content>
      <mat-card-actions>
        <mat-list>
          <div mat-subheader>Características:</div>
          <mat-list-item *ngFor="let folder of it.folders">
            <mat-icon mat-list-icon>{{folder.icon}}</mat-icon>
            <div mat-line>{{folder.name}}</div>
            <div mat-line> {{folder.content}} </div>
          </mat-list-item>
          <mat-divider></mat-divider>
        </mat-list>
      </mat-card-actions>
      <div style="text-align: center;">
        <img style="width: 13%;" src="../../assets/logo_final_ok.png" />
      </div>
    </mat-card>
    <div class='menu-separator'>
      {{ (index + 1) }}
    </div>
    <mat-paginator [length]="numberOfElements"
    [pageSize]="pagesSize"
    [pageIndex]="index"
    (page)="getBottles(searchName, $event.pageIndex, $event.pageSize)"
    [pageSizeOptions]="[5, 10, 15, 100]"
    >
  </mat-paginator>
  <div *ngIf="pageEvent">
    {{ (pageEvent.pageIndex + 1)}}
  </div>
</ng-container>
<ng-container *ngIf="bottles.length <= 0">
  <div style="margin: 10px; width: 100%; text-align: center;">
    <div style="text-align: center;">
      <img src="../../assets/search.png" />
    </div>
    <span class='mat-subheading-1'>Nenhum resultado encontrado para: <b>{{searchName}}</b> </span>
  </div>
</ng-container>
</div>

