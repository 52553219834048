import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BottleService {

  constructor(private http: HttpClient) { }

  getBottles(name, page, size): Observable<any>{
    if(name != ""){
      return this.http.get("https://api.ajudarsalva.com.br:8080/api/v1/bottle/search?query="+name+"&page="+page+"&size="+size)
    }else{
      return this.http.get("https://api.ajudarsalva.com.br:8080/api/v1/bottle?page="+page+"&size="+size)
    }
  }

  getMap(): Observable<any>{
    return this.http.get("https://api.ajudarsalva.com.br:8080/api/v1/query/data")
  }
}
