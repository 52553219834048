import { Component, Input, OnInit } from '@angular/core';

interface State {
  state: String;
  position: Number;
}

@Component({
  selector: 'app-mapa-brasil',
  templateUrl: './mapa-brasil.component.html',
  styleUrls: ['./mapa-brasil.component.scss']
})
export class MapaBrasilComponent implements OnInit {

  constructor() { }

  @Input("states")
  states: Array<State>;

  ngOnInit(): void {
  }

  getColor(state){
    var sta = this.states.filter(s => s.state == state);
    if(sta.length > 0){
      return "st"+sta[0].position
    }else{
      return "inactive"
    }
  }
}
