import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingComponent } from './loading/loading.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { MapaComponent } from './mapa/mapa.component';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import { MatPaginatorIntlBr } from './pag-intlr/matpaginator';
import { HttpClientModule } from '@angular/common/http';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { SearchDialogComponent } from "./search-dialog/search-dialog.component";
import {MatDialogModule} from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import { InfograficComponent } from './infografic/infografic.component';
import { MapaBrasilComponent } from './mapa-brasil/mapa-brasil.component';
import {MatTableModule} from '@angular/material/table';
import { GraficoPizzaComponent } from './grafico-pizza/grafico-pizza.component';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    HomeComponent,
    HeaderComponent,
    MapaComponent,
    SearchDialogComponent,
    InfograficComponent,
    MapaBrasilComponent,
    GraficoPizzaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatGridListModule,
    MatListModule,
    MatPaginatorModule,
    HttpClientModule,
    MatDividerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatChipsModule,
    MatFormFieldModule,
    MatTableModule,
    MatInputModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlBr}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
