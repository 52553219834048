<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>BUDEGA DO CHICO</span>
    <span class="example-spacer"></span>
    <button [matMenuTriggerFor]="menu" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let menuItem of menuHelper">
        <button mat-menu-item [routerLink]="menuItem.route" [disabled]=menuItem.disabled>
          <mat-icon>{{ menuItem.icon }}</mat-icon>
          <span>{{ menuItem.name }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
