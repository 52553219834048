import { Component, Inject, OnInit } from '@angular/core';
import {DialogData} from '../../app/model/dialog-data';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit {

  messageDialog: String = "";
  typeDialog: String = "";
  isErrorDialog = false;

  constructor(@Inject(MAT_DIALOG_DATA) data: DialogData) {
    this.messageDialog = data.message;
    this.typeDialog = data.type;
  }

  ngOnInit(): void {
  }

}
