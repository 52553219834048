import { Component, Input, OnInit } from '@angular/core';
import * as CanvasJS from '../../assets/canvasjs.min';

interface Point{
  y: number;
  name: String;
}

@Component({
  selector: 'app-grafico-pizza',
  templateUrl: './grafico-pizza.component.html',
  styleUrls: ['./grafico-pizza.component.scss']
})
export class GraficoPizzaComponent implements OnInit {

  constructor() { }

  @Input("points")
  points: Array<Point>

  ngOnInit() {
    let chart = new CanvasJS.Chart("chartContainer", {
      theme: "light2",
      animationEnabled: true,
      exportEnabled: false,
      title:{
        text: "Divisão de Rótulos"
      },
      data: [{
        type: "pie",
        showInLegend: true,
        toolTipContent: "<b>{name}</b>: ${y} (#percent%)",
        indexLabel: "{name} - #percent%",
        dataPoints: this.points
      }]
    });

    chart.render();
      }

}
