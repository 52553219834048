import { InfograficComponent } from './infografic/infografic.component';
import { MapaComponent } from './mapa/mapa.component';
import { HomeComponent } from './home/home.component';
import { LoadingComponent } from './loading/loading.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'loading',
    component: LoadingComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path : 'l',
    component: LoadingComponent
  },
  {
    path: 'mapa',
    component: MapaComponent
  },
  {
    path: 'infografico',
    component: InfograficComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
